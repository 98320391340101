<template>
  <div id="app-question-add" class="app-add">
    <loading :loading="loading" text="提交中"></loading>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="材料" name="cl">
      <el-form ref="form" :model="question" :rules="rules"  label-width="100px">
        <major-form-item ref="majorFormItem"></major-form-item>
        <source-form-item ref="sourceFormItem" @parentEvent="getSourceId" :model="question.sourceId" :parent="this" prop="sourceId" ></source-form-item>
        <el-form-item label="材料" prop="stem">
          <el-input type="textarea" :rows="20" v-model="question.stem" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item style="display: none">
          <el-button @click="submit('form')" id="layuiadmin-app-form-submit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-tab-pane>
    <el-tab-pane v-for="i in childNum" :label="'第'+i+'问'" :name="'第'+i+'问'">
      <cl-part ref="part" :subject-id="question.subjectId" :knowledges="knowledges" :laws="laws" :difficults="difficults"></cl-part>
    </el-tab-pane>
  </el-tabs>
  </div>
</template>

<script>
import {request, TipEnum, UrlEnum,QuestionTypeEnum,getLocalProjectId} from "../../../../public/js/common-vue";
import ClPart from "@/components/ClPart";
import MajorFormItem from "@/components/MajorFormItem";
import SourceFormItem from "@/components/SourceFormItem";
import Loading from "@/components/Loading";
export default {
  name: "Add",
  data() {
    return {
      activeName: 'cl',
      question: {
        sourceId: '',
        children: [],
        knowledgeIds: [],
        lawIds: [],
        typeId: request("typeId"),
        scoreWords: [],
        difficultId: 1,
        answer: "",
        analysisText: "",
        analysisVideo: 0,
        parentId: 0,
        projectId: getLocalProjectId(),
        subjectId: request("subjectId"),
        examPoint: "",
        majorIds:[]
      },
      rules: {
        sourceId: [{required: true, message: '请输入', trigger: 'change'}],
        stem: [{required: true, message: '请输入', trigger: 'blur'}],
      },
      loading: false,
      knowledges: [],
      laws: [],
      difficults:[],
      childNum:0
    }
  },
  components: {Loading, SourceFormItem,ClPart,MajorFormItem},
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getSourceId(data) {
      this.question.sourceId = data;
    },
    getKnowledges() {
      this.$http({
        method: "get",
        url: UrlEnum.KNOWLEDGE_TREE + "?subjectId=" + request("subjectId"),
        data: '',
      })
          .then((res) => {
            this.knowledges = res.data.list;
            this.getLaws();
          })
          .catch((res) => {
            this.loading = false;
            if (res.response != null) {
              const data = res.response.data;
              this.showMsgError(data.message);
            }
          })
    },
    getLaws() {
      this.$http({
        method: "get",
        url:UrlEnum.LAW_TREE+"?projectId="+getLocalProjectId(),
        data: '',
      })
      .then((res) => {
        this.laws = res.data.list;
        this.getDifficults();
      })
      .catch((res) => {
        this.loading = false;
        if (res.response != null) {
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      })
    },
    getDifficults() {
      this.$http({
        method: "get",
        url: UrlEnum.QUESTION_DIFFICULT,
        data: '',
      })
      .then((res) => {
        this.difficults = res.data;
        this.childNum=11;
      })
      .catch((res) => {
        this.loading = false;
        if (res.response != null) {
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      })
    },
    addChildren(child) {
      question.children.push(child);
    },
    getChildren() {
      for (let i = 0; i < 11; i++) {
        this.$refs.part[i].submit();
        let child = this.$refs.part[i].question;
        if(child.stem.trim() != ''){
          this.question.children.push(child);
        }
      }
    },
    submit(formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.question.majorIds = this.$refs.majorFormItem.getCheckedIds();
          this.getChildren();
          this.$http({
            method: "post",
            url: UrlEnum.QUESTION,
            data: this.question,
          })
          .then((res) => {
            this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
            const index = parent.layer.getFrameIndex(window.name); //先得到当前iframe层的索引
            setTimeout(function () {
              parent.layui.table.reload('tableData'); //重载表格
              parent.layer.close(index); //再执行关闭
            }, 800);
          })
          .catch((res) => {
            this.loading = false;
            if (res.response != null) {
              const data = res.response.data;
              this.showMsgError(data.message);
            }
          });
        }
      })
    },
  },
  mounted() {
    this.$refs.sourceFormItem.initData(null);
    this.$refs.majorFormItem.initData(null);
    this.getKnowledges();
  }
}
</script>

<style scoped>
@import "../../../../static/css/add.css";
iframe{
  width:100%;
  height: 1030px;
  border: 0px;
}
</style>
