<template style="background-color: white">
  <div id="app-question-add">
    <loading :loading="loading" text="提交中"></loading>
    <el-form ref="form" :model="question" :rules="rules"  label-width="100px">
      <knowledge-form-item ref="knowledgeFormItem"></knowledge-form-item>
      <el-form-item label="法条">
        <select-tree-law ref="lawTree"></select-tree-law>
      </el-form-item>
      <el-form-item label="难易度">
        <select-difficult ref="selectDifficult" @parentEvent="getDifficultId"></select-difficult>
      </el-form-item>
      <el-form-item label="题干" prop="stem">
        <el-input type="textarea" :rows="4" v-model="question.stem" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="答案">
        <el-input type="textarea" :rows="4" v-model="question.answer" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="文字解析">
        <el-input type="textarea" :rows="4" v-model="question.analysisText" placeholder = "请输入"></el-input>
      </el-form-item>
      <el-form-item label="视频解析" prop="analysisVideo">
        <el-input type="number" v-model="question.analysisVideo" placeholder="百家云视频ID"></el-input>
      </el-form-item>
      <el-form-item label="考点" prop="examPoint">
        <el-input type="text" v-model="question.examPoint" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="得分点">
        <score-word ref="scoreWord"></score-word>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {setBodyEvent} from "../../public/question/js/question-vue";
import {getLocalProjectId, request, TipEnum, UrlEnum,QuestionTypeEnum} from "../../public/js/common-vue";
import KnowledgeFormItem from "@/components/KnowledgeFormItem";
import SelectTreeLaw from "@/components/SelectTreeLaw";
import SelectDifficult from "@/components/SelectDifficult";
import ScoreWord from "@/components/ScoreWord";
import Loading from "@/components/Loading";
export default {
  props:['subjectId','knowledges','laws','difficults','initQuestion'],
  name: "Add",
  data(){
    return{
      question:{
        difficultId:1,
        typeId:QuestionTypeEnum.CAILIAO_CHILD,
        stem:'',
        answer:'',
        analysisText:'',
        analysisVideo:0,
        scoreWords:[],
        subjectId:this.subjectId,
        options:[],
        parentId:0,
        projectId:getLocalProjectId(),
        knowledgeIds:[],
        knowledges:[],
        lawIds:[],
        laws:[],
        sourceId:0,
        examPoint:'',
      },
      loading: false,
      rules:{
        stem :[{required: true, message:'请输入', trigger:'blur'}],
        analysisVideo: [{required:true,message:'请输入',trigger:'blur'}]
      },
    }
  },
  components: {Loading, ScoreWord, KnowledgeFormItem,SelectTreeLaw,SelectDifficult},
  methods:{
    getDifficultId(data){
      this.question.difficultId = data;
      console.log(data)
    },
    submit: function () {
      this.question.knowledgeIds = this.$refs.knowledgeFormItem.getCheckedIds();
      this.$refs.knowledgeFormItem.setSessionKnowledges();
      this.question.lawIds = this.$refs.lawTree.getCheckedIds();
      this.question.scoreWords = this.$refs.scoreWord.getScoreWords();
      this.question.majorIds = [];
    },
    getSourceId(data){
      this.question.sourceId = data;
      console.log(data);
    },
  },
  mounted() {
    setBodyEvent(this);
    if(this.initQuestion != null){
      this.question = this.initQuestion;
    }
    this.$refs.knowledgeFormItem.initData(this.knowledges,this.question.knowledges);
    this.$refs.lawTree.initData(this.laws,this.question.laws);
    this.$refs.selectDifficult.initData(this.difficults,this.question.difficultId);
    this.$refs.scoreWord.initData(this.question.scoreWords);
  },
}
</script>

<style scoped>
</style>
